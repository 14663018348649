.productContainer {
  @apply flex flex-row pb-3 pt-6 w-full justify-between;
}

.productImage {
  @apply rounded-lg border border-[#4466B0] w-full;
}

.productImageLink {
  @apply mr-3;
}

.productTitle {
  @apply flex font-medium flex-col justify-between w-2/3 lg:h-fit;
  font-family: 'droid-serif', serif;
  font-weight: 700;
  font-style: normal;
}

.productLink {
  @apply cursor-pointer;
}

.productInfo {
  @apply text-sm font-normal text-spring-neutral-50;
  font-family: 'droid-serif', serif;
  font-weight: 400;
  font-style: normal;
}

.trashContent {
  @apply text-sm text-spring-neutral-70 cursor-pointer;
}

.currencyContent {
  @apply flex flex-col justify-between text-right  w-1/3;
  font-family: 'droid-serif', serif;
  font-weight: 700;
  font-style: normal;
}

.hiddenMdBlock {
  @apply hidden md:block pr-2;
}

.hiddenMdBlockTextSmTextSlate400 {
  @apply hidden md:block text-sm text-slate-400;
}

.mdHiddenBlockPb6 {
  @apply md:hidden block pb-6;
}

.iconStyle {
  @apply h-6 w-6;
}

.mobileQuantity {
  @apply h-10 w-8;
}

.quantitySelector {
  @apply md:hidden block pr-2;
}
